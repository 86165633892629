//
// Component: Sidebar Mini
//

// Logo style
.logo-xs,
.logo-xl {
  margin: 0;
  opacity: 1;
  position: absolute;
  visibility: visible;

  &.brand-image-xs {
    left: 18px;
    top: 12px;
  }

  &.brand-image-xl {
    left: 12px;
    top: 6px;
  }
}

.logo-xs {
  opacity: 0;
  visibility: hidden;

  &.brand-image-xl {
    left: 16px;
    top: 8px;
  }
}

.brand-link {
  &.logo-switch {
    &::before {
      content: '\00a0';
    }
  }
}

// Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  @include media-breakpoint-up(lg) {
    @include sidebar-mini-breakpoint;
  }
}

.sidebar-mini-md {
  @include media-breakpoint-up(md) {
    @include sidebar-mini-breakpoint;
  }
}

.nav-sidebar {
  position: relative;

  &:hover {
    overflow: visible;
  }
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;

  > .float-right {
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.main-sidebar .logo-xs,
.main-sidebar .logo-xl,
.sidebar .user-panel .info {
  @include transition(margin-left $transition-speed linear, opacity $transition-speed ease, visibility $transition-speed ease)
}
