//
// Component: Timeline
//

.timeline {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  position: relative;

  // The line
  &::before {
    @include border-radius($border-radius);
    background: $gray-300;
    bottom: 0;
    content: '';
    left: 31px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  > li {
    @include clearfix;
    margin-bottom: 15px;
    margin-right: 10px;
    position: relative;

    // The content
    > .timeline-item {
      @include box-shadow($card-shadow);
      @include border-radius($border-radius);
      background: $white;
      color: $gray-700;
      margin-left: 60px;
      margin-right: 15px;
      margin-top: 0;
      padding: 0;
      position: relative;

      // The time and header
      > .time {
        color: #999;
        float: right;
        font-size: 12px;
        padding: 10px;
      }

      > .timeline-header {
        border-bottom: 1px solid $card-border-color;
        color: $gray-700;
        font-size: 16px;
        line-height: 1.1;
        margin: 0;
        padding: 10px;

        > a {
          font-weight: 600;
        }
      }

      // Item body and footer
      > .timeline-body,
      > .timeline-footer {
        padding: 10px;
      }

    }

    // The icons
    > .fa,
    > .fas,
    > .far,
    > .fab,
    > .glyphicon,
    > .ion {
      background: $gray-500;
      border-radius: 50%;
      font-size: 15px;
      height: 30px;
      left: 18px;
      line-height: 30px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 30px;
    }
  }

  // Time label
  > .time-label {
    > span {
      @include border-radius(4px);

      background-color: $white;
      display: inline-block;
      font-weight: 600;
      padding: 5px;
    }
  }
}

.timeline-inverse {
  > li {
    > .timeline-item {
      @include box-shadow(none);
      background: $gray-100;
      border: 1px solid $gray-300;

      > .timeline-header {
        border-bottom-color: $gray-300;
      }
    }
  }
}
